@media only screen and (max-width: 600px) {
  body,
  html {
    max-width: 100vw;
    box-sizing: border-box;
    max-width: 100vw;
    width: 100vw;
    /* overflow: hidden; */
  }
  .portada-products {
    background-image: url("./images/products-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: gray;
    background-blend-mode: multiply;
    margin: 0 0 15vh 0;
    padding: 10vh 5% 0 5%;
    height: 60vh;
    gap: 4vh;
  }
  .h2-portada {
    margin: 0 5% 0 5%;
    font-size: 6vw;
  }

  .swiper {
    margin: 10vh 10% 10vh 10%;
    width: 100vw;
    z-index: -1;
  }

  .swiper-wrapper {
    width: 100%;
  }
  .product-button {
    margin-top: 2vh;
    padding: 2vh;
  }
}

@media only screen and (min-width: 600px) {
}
