/* 1vw = 14.5834 en mi macbook air m1 */

/*CSS*/
body {
  /* background: linear-gradient(147.33deg, #3d509e 13.99%, #040b28 68.81%); */
  background: linear-gradient(147.33deg, #3d509e 13.99%, #040b28 68.81%);
  flex-wrap: wrap;
  overflow-x: auto;
  white-space: nowrap;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 11.7px 30px;
  position: absolute;

  background: none;
  top: 0px;
  z-index: 1;
  width: 100vw;
  height: 10vh;
}

.header.active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* position: sticky;
  top: 0; */
  background: black;
  position: fixed;
  top: 0px;
  transition-duration: 0.4s;
}

.header button {
  background: none;
  border: none;
}

.navbar-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 39px;
  font-family: "Mada", sans-serif;
}
.navbar-list a {
  text-decoration: none;
  color: white;
}

.navbar-list a:hover {
  color: rgb(219, 219, 219);
}

.alp-logo {
  width: auto;
  height: 43.75px;
}
.portada-gral {
  top: -10vh;
  padding-top: 120px;
  padding-left: 8vw;
  margin: 0px 0 200px 0;
  height: 110vh;
}
.portada {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-image: url(./images/Group14.jpg);
}
.portada1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 10vh;
}

.felder-logo {
  padding-left: 35%;
  padding-bottom: 30%;
  height: 160px;
  z-index: 1;
}

.titular {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 70px;
  line-height: 85px;
  margin-top: 100px;
}

.texto-portada {
  font-family: "Mada", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: white;
}
a {
  text-decoration: none;
}
.contact-button {
  display: flex;
  border: none;
  background-color: #df5c37;
  width: 200px;
  height: 44px;
  border-radius: 48px;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  transition-duration: 0.5s;
}

.contact-button:hover {
  background-color: white;
  color: #df5c37;
  border: solid #df5c37;
  transition-duration: 0.5s;
}

.smiling-guy {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  /* width: 100%; */
  width: fit-content;
  height: 655px;
  max-height: 100%;
  filter: drop-shadow(6px 9px 18px rgba(0, 0, 0, 0.25));
}

.view2 {
  margin-top: 146px;
  margin-bottom: 292px;
}
.h2-portada {
  text-align: center;
  color: #df5c37;
  font-family: "Mada";
  font-weight: 700;
  font-size: 2.7vw;
}

.info-container {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

.img-circular {
  filter: drop-shadow(6px 9px 18px rgba(0, 0, 0, 0.25));
  height: fit-content;
  width: 43.5vw;
}

.info-text {
  color: white;
  right: 0;
  width: 100%;
  padding-left: 18.7vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1.45vw;
}

.button-white {
  background: none;
  border: 1px solid white;
  display: flex;
  width: 200px;
  height: 44px;
  border-radius: 48px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  transition-duration: 0.5s;
}

.button-white:hover {
  background: white;
  border: 1px solid white;
  color: #3d509e;
  transition-duration: 0.5s;
}

.info-text a {
  text-decoration: none;
}

.marcas {
  background-color: white;
  margin: 40px;
  padding: 6vw 29px 154px 29px;
  border-radius: 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.marcas h2 {
  color: #3d509e;
  font-family: "Mada", sans-serif;
  font-size: 45px;
  text-align: center;
}
.marcas-container {
  margin-top: 44px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 60px 10px;
  margin-bottom: 72px;
  width: fit-content;
}
.marca-felder,
.marca-format4,
.marca-hammer,
.marca-mayer {
  /* max-width: fit-content; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
}

.marcas img {
  max-width: 50%;
}

.marcas a {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.view4 {
  display: flex;
  justify-content: space-around;
  margin-top: 30vh;
  margin-bottom: 50vh;
}

.view4 h2 {
  font-family: "Mada";
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  color: white;
}

.view4 p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: white;
}

.icon-left4 {
  height: fit-content;
  margin-right: 43.75px;
  margin-top: 36.5px;
}

.servgaran {
  display: flex;
  align-items: flex-start;
}

.img-craftsman {
  /* position: absolute; */
  margin-left: -8vw;
  margin-top: 145px;
  width: 50vw;
  max-height: fit-content;
  max-width: fit-content;
}
.view4-left {
  display: flex;
  flex-direction: column;
  gap: 8vh;
}
.view4-right {
  display: flex;
  flex-direction: column;
  gap: 115px;
}
.view4-pasos {
  display: flex;
  flex-direction: column;
  gap: 6vh;
}

.titular-pasos {
  display: flex;
  align-items: center;
  height: fit-content;
}

.view4-right img {
  height: fit-content;
}

.view4-right h2 {
  color: #df5c37;
  font-size: 75px;
  margin: 0 0 0 29px;
  font-family: "Montserrat";
  font-weight: 700;
}

.marcas .a-button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
  color: white;
  background-color: #df5c37;
  padding: 10px 60px;
  border-radius: 40px;
  transition-duration: 0.5s;
}

.marcas .a-button:hover {
  background-color: white;
  color: #df5c37;
  border: solid #df5c37;
  transition-duration: 0.5s;
}

.view5 {
  background-color: white;
  margin: 40px;
  padding: 66px 30px 66px 30px;
  border-radius: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review {
  display: flex;
  flex-direction: column;
  gap: 10vh;
}

.review h2 {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 60px;
  line-height: 85px;
  text-align: center;
  color: #3d509e;
}

.review h3 {
  font-family: "Mada";
  font-weight: 600;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  color: #df5c37;
}

.view6 {
  margin-top: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 230px;
}

.view6 h2 {
  font-family: "Mada";
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 116px;
}

hr {
  margin: 0 2% 0 2%;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2vh 2% 2vh 2%;
  color: white;
  z-index: 1000;
}

footer p {
  font-family: "Manrope";
  font-weight: 200;
  font-size: 14.6px;
  font-weight: lighter;
}

.social-icons {
  height: 22px;
  display: flex;
  gap: 14.6px;
}

.social-icons a {
  color: inherit;
  text-decoration: none;
}
