.aboutus-components {
  height: 10px;
}

.portada-about {
  display: flex;
  flex-direction: column;
  gap: 10vh;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.container-about2 {
  margin: 200px 0 0 0;
  width: 100vw;
  padding: 0 0 0 10vw;
  color: white;
  display: flex;
  justify-content: space-between;
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 800px;
  width: 100%;
  z-index: -1000;
  position: absolute;
  top: 0;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-aboutus {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1.6457vw;
  color: white;
}

.about2-right {
  display: flex;
  flex-direction: column;
}
.about2-right img {
  width: 100%;
}

.about2-left {
  display: flex;
  flex-direction: column;
  gap: 4vh;
}

.h3-aboutus {
  font-family: "Mada";
  font-weight: 700;
  font-size: 45px;
  color: white;
}
