/* 1vw = 14.5834 en mi macbook air m1 */

.contact-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1000px;
  margin-bottom: 10vw;
  margin-top: 10vh;
}

.contact-component h1 {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 4.8vw;
  line-height: 5.8285vw;
  color: white;
  text-align: center;
  margin-bottom: 0;
}

.contact-component h2 {
  font-family: "Mada";
  font-style: normal;
  font-weight: 500;
  font-size: 2.7428vw;
  line-height: 3.5657vw;
  color: white;
  margin-top: 1vw;
  margin-bottom: 5vw;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
}
.contact-form label {
  color: white;
  margin-left: 1vw;
}
.container-label {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
.noterror-form {
  width: 38vw;
  border-radius: 1vw;
  border: none;
  padding: 1vw;
  font-family: "Inter";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contact-form textarea {
  width: 38vw;
  border-radius: 1.3vw;
  border: 1px white solid;
  padding: 1vw;
  font-family: "Inter";
}

.error-form {
  width: 38vw;
  border-radius: 1.3vw;
  border: 2px solid red;
  padding: 1vw;
  font-family: "Inter";
}

.mensaje-error {
  color: red;
  margin: 0;
  position: absolute;
  font-size: smaller;
  padding-left: 10px;
}
