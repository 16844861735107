* {
  margin: 0;
  box-sizing: border-box;
}

nav {
  width: 100%;
  height: 8vh;
  background-color: black;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh;
  z-index: 1;
}

.burger-menu {
  position: sticky;
  height: 100%;
  width: 5.5vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.burger-bar {
  width: 5.5vh;
  height: 0.4vh;
  background-color: white;
  border-radius: 0.4vh;
}

.menu {
  position: fixed;
  width: 100vw;
  height: 40vw;
  background-color: rgb(22, 22, 22);
  top: 4em;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  z-index: inherit;
  padding-top: 3.5vh;
}

/*---------------- SLIDING MENU ----------------*/
.hidden {
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

.visible {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

/*---------------- ANIMATIONS ----------------*/
/*----------- clicked -----------*/

.burger-bar.clicked:nth-child(1) {
  transform: rotate(45deg) translate(1.5vh, 1.5vh);
  transition: ease-out 0.5s;
}
.burger-bar.clicked:nth-child(2) {
  transform: scale(0.1);
  transition: ease-out 0.5s;
}
.burger-bar.clicked:nth-child(3) {
  transform: rotate(135deg) translate(-1vh, 1vh);
  transition: ease-out 0.5s;
}
/*----------- unclicked -----------*/
.burger-bar.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

/*----------- OTROS ESTILOS -----------*/

.menu ul {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.burger-item {
  margin-left: -15px;
  list-style: none;
  margin-bottom: 20px;
  text-decoration: none;
}

.burger-item a {
  color: white;
  z-index: 100;
  opacity: 1;
}
