@media only screen and (max-width: 600px) {
  body,
  html {
    max-width: 100vw;
    box-sizing: border-box;
    max-width: 100vw;
    width: 100vw;
    /* overflow: hidden; */
  }
  .contact-component {
    gap: 2vh;
    padding-top: 15vh;
  }
  .contact-component h1 {
    font-size: 4.5vh;
    line-height: normal;
  }
  .contact-component h2 {
    font-size: 2.5vh;
    line-height: normal;
  }

  .contact-form {
    gap: 5vh;
  }
  .noterror-form {
    width: 90vw;
    padding: 2vw;
    height: 6vh;
    font-size: 2vh;
  }

  .error-form {
    width: 90vw;
    padding: 2vw;
    height: 6vh;
    font-size: 2vh;
    z-index: -10;
  }

  .mensaje-error {
    font-size: 1.3vh;
  }

  .contact-form textarea {
    width: 90vw;
    padding: 2vw;
    font-size: 2vh;
  }
}

@media only screen and (min-width: 600px) {
}
