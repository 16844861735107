.contenedor-carrusel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.swiper {
  width: 100%;
  margin: 100px;
  height: 500px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  height: 450px;
  width: 330px;
  background: #fff;
  z-index: 0;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.slide-div {
  height: 100%;
  width: 100%;
  transition-duration: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .slide-div:hover {
  height: 500px;
  background-color: rgb(232, 232, 232);
  transition-duration: 0.5s;
} */

.img-carousel {
  height: 250px;
  object-fit: cover;
  transition-duration: 0.5s;
}
.img-carousel:hover {
  height: 300px;
  object-fit: cover;
  transition-duration: 0.5s;
}

.nombre-producto {
  color: #3d509e;
  font-family: "Roboto";
  font-size: 24px;
  margin: 15px 0;
}

.product-button {
  display: flex;
  padding: 5px 20px 5px 20px;
  border: 1px solid #df5c37;
  background-color: white;
  border-radius: 48px;
  align-items: center;
  justify-content: center;
  color: #df5c37;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 1.1657vw;
  transition-duration: 0.5s;
}

.product-button:hover {
  color: white;
  background-color: #df5c37;
  transition-duration: 0.5s;
}

.swiper-pagination-bullet {
  background-color: white;
}
