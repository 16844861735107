@media only screen and (max-width: 600px) {
  .portada-about {
    background-image: url("./images/about-us-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: gray;
    background-blend-mode: multiply;
    margin: 0 0 15vh 0;
    padding: 10vh 5% 0 5%;
    height: 70vh;
    gap: 4vh;
  }
  .texto-portada {
    line-height: normal;
  }
  .container-about2 {
    margin: 0 5% 0 5%;
    padding: 0;
  }
  .about2-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
  }
  .h3-aboutus {
    font-size: 4vh;
  }
  .text-aboutus {
    font-size: 2.2vh;
    text-align: center;
  }
}

@media only screen and (min-width: 600px) {
}
