.products-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1000px;
}
.portada-products {
  height: 120vh;
  display: flex;
  flex-direction: column;
  gap: 8vh;

  background-image: url("./images/products-web.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: gray;
  background-blend-mode: multiply;
}

#button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10vh;
  margin-bottom: 5vh;
}
