@media only screen and (max-width: 600px) {
  body {
    max-width: 100vw;
    box-sizing: border-box;
    max-width: 100vw;
    width: 100vw;
    /* overflow: hidden; */
  }

  .just-web {
    display: none;
  }
  .header {
    display: none;
  }
  .header.active {
    display: none;
  }
  /* .img-background {
    top: 4em;
    clip-path: inset(0px 20px 0px 100px);
    width: 150%;
    left: -100px;
    object-fit: cover;
  } */
  .portada {
    margin-top: 20vh;
    padding-top: 10vh;
    height: 85vh;
    margin-bottom: 0;
    background-image: url(./images/home-mobile.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: gray;
    background-blend-mode: multiply;
  }

  .portada1 {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .titular {
    font-size: 10vw;
    line-height: normal;
  }
  .texto-portada {
    font-size: 4.2vw;
    margin: 5% 0 5% 0;
  }
  .contact-button {
    width: 40vw;
    font-size: 2.8vw;
    padding: 4vw 25vw 4vw 25vw;
  }
  .felder-logo {
    display: none;
  }
  .view2 {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    gap: 10vw;
  }
  .h2-portada {
    font-size: 7vw;
    line-height: normal;
  }
  .info-container {
    margin: 0;
  }
  .info-text {
    font-size: 3.8vw;
    text-align: center;
    padding: 0 5% 0 5%;
    gap: 10vw;
  }
  .info-text a {
    display: flex;
    justify-content: center;
  }
  .button-white {
    font-size: 3vw;
  }
  .marcas {
    margin: 0;
  }
  .marcas h2 {
    margin: 13vw;
    font-size: 10vw;
  }
  .marcas-container {
    margin: 0;
    padding: 0;
    width: fit-content;
    margin-bottom: 12vw;
  }
  .marca-felder,
  .marca-format4,
  .marca-hammer,
  .marca-mayer {
    font-size: 4.5vw;
    gap: 5vw;
    text-align: center;
  }
  .view4 {
    flex-direction: column;
    margin: 5%;
  }
  .view4-left {
    display: flex;
    flex-direction: column;
    gap: 6vw;
    margin-top: 20vw;
  }
  .view4-left h2 {
    font-size: 10vw;
  }
  .view4-left p {
    font-size: 4.5vw;
  }
  .servgaran {
    display: flex;
    flex-direction: column;
    gap: 3vw;
  }
  .servgaran p {
    font-size: 4.5vw;
  }
  .img-craftsman {
    position: inherit;
    width: 99.5vw;
    margin: 10vw 0 15vw -5%;
  }
  .view4-pasos {
    gap: 6vw;
  }
  .titular-pasos {
    gap: 6vw;
  }
  .view4-right p {
    font-size: 4.3vw;
  }
  .view5 {
    margin: 40vw 0 60vw 0;
  }
  .review {
    display: flex;
    flex-direction: column;
    gap: 10vw;
  }
  .review h2 {
    text-align: left;
    font-size: 6.5vw;
    line-height: normal;
  }
  .review h3 {
    text-align: left;
    font-size: 4vw;
    line-height: normal;
  }
  .slogan h2 {
    font-size: 7vw;
    line-height: normal;
  }
}

@media only screen and (min-width: 600px) {
  .just-mobile {
    display: none;
  }
}
